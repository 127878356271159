import { cdekConsoleLog } from '@/utils/console-wrapper';

export function addedGAEvent(event: string, params: Record<string, unknown> = {}) {
  if (!event) {
    return;
  }

  cdekConsoleLog(event);

  const payload = { ...params, event };

  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(payload);
  }
}
